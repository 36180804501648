import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-image-cropper-dialog',
	templateUrl: './image-cropper-dialog.component.html',
	styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent {
	croppedImage: Blob | null | undefined;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			imageFile: File;
		},
		public dialogRef: MatDialogRef<ImageCropperDialogComponent>
	) {}

	imageCropped(event: ImageCroppedEvent) {
		if (event.blob) {
			this.croppedImage = event.blob;
		}
	}

	onCanceled() {
		this.dialogRef.close(null);
	}

	onCropped() {
		this.dialogRef.close(this.croppedImage);
	}
}
