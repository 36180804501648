import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import RoutingConstant from '../../../app-routing.constant';
import { DialogConfirmationWriterService } from '@components/dialog-confirm-writer/dialog-confirm-writer.service';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { REMOTE_CONFIG_KEYS } from '@common/constants';
import { DialogConfirmationPublisherService } from '@components/dialog-confirm-publisher/dialog-confirm-publisher.service';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { ProfileModel } from '@data/models/profile.model';

@Component({
	selector: 'app-bottom-navigation',
	templateUrl: './bottom-navigation.component.html',
	styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent implements OnInit {
	RoutingConstant = RoutingConstant;
	currentRoute = '/';
	isMobileAppView = false;
	showBookShop = true;
	userDetail: ProfileModel | null = null;

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private dialogConfirmWriter: DialogConfirmationWriterService,
		private dialogConfirmPublisher: DialogConfirmationPublisherService,
		private remoteConfig: AngularFireRemoteConfig,
		public authentication: AuthenticationUsecase
	) {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd || Scroll)) {
				return;
			}

			if (evt instanceof NavigationEnd) {
				this.currentRoute = evt.url;
			}
			window.scrollTo(0, 0);

			// Get query parameters from the active route snapshot
			const snapshot = this.activeRoute.snapshot;
			const queryParams = snapshot.queryParams;
			const isMobileAppView = queryParams['isMobileAppView'];

			if (isMobileAppView) {
				this.isMobileAppView = isMobileAppView.toLowerCase() === 'true';
			}
		});
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.currentRoute = event.url;
			}
		});
	}

	ngOnInit(): void {
		// this.remoteConfig.getBoolean(REMOTE_CONFIG_KEYS.SHOW_BOOK_STORE).then((value) => {
		// 	this.showBookShop = value;
		// });

		if (this.authentication.isUserLoggedIn() && !this.userDetail) {
			this.userDetail = this.authentication.getUser();
		}
	}

	openDialogWriter() {
		this.dialogConfirmWriter.open();
	}

	openDialogPublisher() {
		this.dialogConfirmPublisher.open();
	}
}
