import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-bottom-sheet-share-ebook',
	templateUrl: './bottom-sheet-share-ebook.component.html',
	styleUrls: ['./bottom-sheet-share-ebook.component.scss']
})
export class BottomSheetShareEbookComponent implements OnInit {
	currentUrl = '';

	constructor(
		private clipboard: Clipboard,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
		private _bottomSheetRef: MatBottomSheetRef<BottomSheetShareEbookComponent>
	) {}

	ngOnInit(): void {
		this.currentUrl = window.location.href;
	}

	closeBottomSheet() {
		this._bottomSheetRef.dismiss(false);
	}

	sendToWhatsapp() {
		window.open(`whatsapp://send?text=${this.currentUrl}`);
		this._bottomSheetRef.dismiss(true);
	}

	copyLink() {
		this.clipboard.copy(this.currentUrl);
		this._bottomSheetRef.dismiss(true);
	}
}
