const RoutingConstant = {
	ROOT: '',
	HOME: '/home',
	BROWSE_BY_GENRE: '/browse/genre',
	LIST_KARYA: '/menulis/list-karya',
	LIST_EVENTS: '/events',
	CONFIGURATION: '/misc',
	CREATE_POST: (id_post: string) => `/create-post/${id_post}`,
	CREATE_CHAPTER: (id_post: string) => `/create-post/${id_post}/create-chapter`,
	DETAIL_EVENT: (url_web: string) => `/event/detail/${url_web}`,
	SUCCESS_SUBMIT_EVENT: '/event/success-submit',
	UPCOMING_EVENT: '/events/upcoming',
	MONETISAKU_HOMEPAGE: '/monetisaku/homepage',
	MONETISAKU_DASHBOARD: '/monetisaku/dashboard',
	MONETISAKU_SELECT_POST: '/monetisaku/select-post',
	MONETISAKU_ENROLLMENT_STATUS: (enrollment_id: string) =>
		`/monetisaku/enrollment/status/${enrollment_id}`,
	MONETISAKU_TERM_CONDITION: '/monetisaku/terms-condition',
	MONETISAKU_ENROLLMENT_LIST: '/monetisaku/enrollment/list',
	MONETISAKU_WITHDRAWAL_REQUEST: '/monetisaku/withdrawal/request',
	MONETISAKU_WITHDRAWAL_HISTORY: '/monetisaku/withdrawal/history',
	JOIN_EVENT: (event_id: string) => `/join-event/${event_id}`,
	BROWSE: '/browse',
	SEARCH: '/search',
	PROFILE: '/profile',
	PROFILE_DETAIL: '/profile-detail',
	POST_DETAIL: (post_id: string) => `/post/${post_id}`,
	POST_REVIEW: (post_id: string) => `/post-review/${post_id}`,
	CHAPTER_READ: (post_id: string, chapter_id: string) => `/post/${post_id}/${chapter_id}`,
	CHAPTER_COMMENTS: (chapter_id: string) => `/comments/${chapter_id}`,
	TOP_UP: '/topup',
	TOP_UP_SUCCESS: '/topup/success',
	TOP_UP_TERM_CONDITION: '/topup/syarat-ketentuan',
	NOTIFICATION: '/notification',
	LANDING_WRITER_INFO: '/about/writer/info',
	LANDING_WRITER_MONETISAKU: '/about/writer/monetisaku',
	LANDING_WRITER_AINI: '/about/writer/aini',
	LANDING_PARTNERSHIP: '/about/partnership',
	LANDING_AINI_PACKAGE: '/aini/package',
	LANDING_EVENT: '/about/event',
	LANDING_PROGRAM: '/about/program',
	LANDING_BUSINESS: '/about/business',
	LANDING_WRITER_MICROSITE: '/about/writer/100persenroyalti',
	MISC_GENERAL_TERM_CONDITION: '/misc/term-and-conditions',
	SHOP_HOME: '/pustaka',
	SHOP_SEARCH: '/pustaka/search',

	SHOP_MY_COLLECTION: '/pustaka/my-collection',
	SHOP_TRANSACTION: '/pustaka/transaction',
	SHOP_CHECKOUT: (ebook_id: string) => `/pustaka/checkout/${ebook_id}`,

	NEW_LANDING_HOME: '/revamp-landing/home',
	NEW_LANDING_WRITER: '/revamp-landing/info-writer',
	NEW_LANDING_MONETISAKU: '/revamp-landing/monetisaku',
	NEW_LANDING_EVENT: '/revamp-landing/event',
	NEW_LANDING_PROGRAM: '/revamp-landing/program',
	NEW_LANDING_BUSINESS: '/revamp-landing/business',

	DETAIL_ACQUISITION: (slug: string) => `/acquisition/${slug}`,
	JOIN_ACQUISITION: (acquisition_id: string) => `/acquisition/join/${acquisition_id}`,
	SUCCESS_SUBMIT_ACQUISITION: (acquisition_id: string) =>
		`/acquisition/join/${acquisition_id}/success-submit`
};
export default RoutingConstant;
