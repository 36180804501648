<div mat-dialog-content class="flex items-center justify-center bg-white">
	<image-cropper
		class="w-full h-auto mobile:w-auto mobile:h-96"
		[maintainAspectRatio]="true"
		[containWithinAspectRatio]="false"
		[aspectRatio]="1 / 1"
		[onlyScaleDown]="false"
		[roundCropper]="false"
		[alignImage]="'center'"
		format="png"
		(imageCropped)="imageCropped($event)"
		[imageFile]="data.imageFile"></image-cropper>
</div>
<div mat-dialog-actions>
	<div class="w-full flex justify-end items-center gap-5 p-5">
		<div class="cursor-pointer text-gray-800" (click)="onCanceled()">Cancel</div>
		<div class="bg-red-600 text-white rounded-md px-4 py-2.5" (click)="onCropped()">Apply</div>
	</div>
</div>
