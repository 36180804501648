import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmWriterComponent } from '@components/dialog-confirm-writer/dialog-confirm-writer.component';

@Injectable({
	providedIn: 'root'
})
export class DialogConfirmationWriterService {
	dialogRef: MatDialogRef<DialogConfirmWriterComponent> | null = null;

	constructor(private dialog: MatDialog) {}

	public open() {
		this.dialogRef = this.dialog.open(DialogConfirmWriterComponent);
	}
}
