<div mat-dialog-content class="flex flex-col items-center justify-center md:w-[420px] rounded-lg">
	<div class="pt-5 text-center py-8 px-6 space-y-6">
		<div class="text-center mt-4">
			Akun yang kamu gunakan sekarang adalah akun Penerbit. Silahkan login ke akun Penulis
			untuk dapat menulis karya.
		</div>
		<div
			class="py-3 cursor-pointer bg-red-600 rounded-full text-white w-fit px-24 mx-auto"
			(click)="onCancel()">
			Tutup
		</div>
	</div>
</div>
