<div class="bottom-navigation" *ngIf="!isMobileAppView">
	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		[routerLink]="[RoutingConstant.HOME]">
		<div
			*ngIf="currentRoute === RoutingConstant.HOME; else notActive"
			style="background-color: transparent">
			<img src="../../../../assets/icons/icon-home-active.svg" class="m-auto" />
			<span class="text-xs font-semibold active-text">Home</span>
		</div>
		<ng-template #notActive>
			<img src="../../../../assets/icons/icon-home-grey.svg" class="m-auto" />
			<span class="text-xs font-medium text-gray-500">Home</span>
		</ng-template>
	</div>

	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		[routerLink]="[RoutingConstant.BROWSE_BY_GENRE]">
		<div *ngIf="currentRoute === RoutingConstant.BROWSE_BY_GENRE; else notActiveKarya">
			<img src="../../../../assets/icons/icon-karya-active.svg" class="m-auto" />
			<span class="text-xs font-semibold active-text">Karya</span>
		</div>
		<ng-template #notActiveKarya>
			<img src="../../../../assets/icons/icon-karya-grey.svg" class="m-auto" />
			<span class="text-xs font-medium text-gray-500">Karya</span>
		</ng-template>
	</div>

	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		(click)="
			userDetail && userDetail.role == 'PUBLISHER'
				? openDialogPublisher()
				: openDialogWriter()
		">
		<div
			class="m-auto -mt-7 p-3 rounded-full self-center"
			[ngClass]="userDetail && userDetail.role == 'PUBLISHER' ? 'bg-slate-300' : 'bg-red-500'"
			style="
				display: flex;
				align-items: center;
				justify-content: center;
				width: 55px;
				height: 55px;
			">
			<img src="../../../../assets/icons/icon-menulis.svg" class="" />
		</div>
		<span class="text-xs font-medium text-gray-500">Menulis</span>
	</div>

	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		[routerLink]="[RoutingConstant.LIST_EVENTS]">
		<div *ngIf="currentRoute === RoutingConstant.LIST_EVENTS; else notActiveEvents">
			<img src="../../../../assets/icons/icon-events-active.svg" class="m-auto" />
			<span class="text-xs font-semibold active-text">Events</span>
		</div>
		<ng-template #notActiveEvents>
			<img src="../../../../assets/icons/icon-events-grey.svg" class="m-auto" />
			<span class="text-xs font-medium text-gray-500">Events</span>
		</ng-template>
	</div>

	<ng-container *ngIf="!showBookShop">
		<div
			class="flex-col flex-1 text-center self-end cursor-pointer"
			[routerLink]="[RoutingConstant.MONETISAKU_DASHBOARD]">
			<div
				*ngIf="
					currentRoute === RoutingConstant.MONETISAKU_DASHBOARD;
					else notActiveMonetisaku
				">
				<img src="../../../../assets/icons/icon-monetisaku-active.svg" class="m-auto w-6" />
				<span class="text-xs font-semibold active-text">Monetisaku</span>
			</div>
			<ng-template #notActiveMonetisaku>
				<img src="../../../../assets/icons/icon-monetisaku-grey.svg" class="m-auto w-6" />
				<span class="text-xs font-medium text-gray-500">Monetisaku</span>
			</ng-template>
		</div>
	</ng-container>

	<ng-container *ngIf="showBookShop">
		<div
			class="flex-col flex-1 text-center self-end cursor-pointer"
			[routerLink]="[RoutingConstant.SHOP_HOME]">
			<div
				*ngIf="
					currentRoute === RoutingConstant.SHOP_HOME ||
						currentRoute === RoutingConstant.SHOP_MY_COLLECTION ||
						currentRoute == RoutingConstant.SHOP_TRANSACTION;
					else notActiveBookStore
				">
				<img src="../../../../assets/icons/icon-tokobuku-active.svg" class="m-auto w-6" />
				<span class="text-xs font-semibold active-text">TokoBuku</span>
			</div>
			<ng-template #notActiveBookStore>
				<img src="../../../../assets/icons/icon-tokobuku.svg" class="m-auto w-6" />
				<span class="text-xs font-medium text-gray-500">TokoBuku</span>
			</ng-template>
		</div>
	</ng-container>
</div>
