import { Observable } from 'rxjs';

export abstract class AcquisitionRepository {
	abstract getAcquisitionBySlug(url: string): Observable<any>;
	abstract registerAcquisition(params: object, id: string): Observable<any>;
	abstract checkAcquisitionRegistrationStatus(id: string): Observable<any>;
	abstract getSubmittedPost(id: string): Observable<any>;
	abstract submitPost(id: string, postIds: string[]): Observable<any>;
	abstract getEligiblePost(acquisitionId: string): Observable<any>;
}
