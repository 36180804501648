import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReadingHistoryRepository } from '../repositories/reading-history.repository';
import { IChapter } from '@data/interface/chapter.interface';
import { IResponseSimplifiedPost } from '@data/interface/post.interface';
import urlHelper from '../../common/urlHelper';
import { DeviceDetectorService } from 'ngx-device-detector/lib/device-detector.service';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import * as CryptoJS from 'crypto-js';

import { default as _rollupMoment } from 'moment/moment';
import * as _moment from 'moment';

const moment = _rollupMoment || _moment;

@Injectable({
	providedIn: 'root'
})
export class ReadingHistoryUsecase {
	constructor(
		private readingHistoryRepository: ReadingHistoryRepository,
		private authService: AuthenticationUsecase
	) {}

	getLatestChapterReadByPostId(postId: string, postTitle: string): Observable<string> {
		return this.readingHistoryRepository.getLatestChapterReadByPostId(postId).pipe(
			map((result: any) => {
				const chapter: IChapter = result.data.chapterId;
				return urlHelper.generateChapterUrl(postId, postTitle, chapter.id, chapter.title);
			})
		);
	}

	logChapterRead(url: string, deviceInfo: DeviceDetectorService): Observable<boolean> {
		if (this.authService.isUserLoggedIn()) {
			return this.readingHistoryRepository
				.logChapterView({
					p: url
				})
				.pipe(
					map((response: any) => {
						return !!response;
					})
				);
		}

		const payload = {
			url,
			...deviceInfo
		};
		const m = moment().format('mm').slice(0, 1);
		const keyStr = CryptoJS.SHA1(moment().format(`WW[31]DDDD[${m}]QHH[33]d[7]`)).toString();
		const ivStr = moment().format(`d[7]WW[${m}]DDDDQ[33]HH[13]`);
		const key = CryptoJS.enc.Hex.parse(keyStr);
		const iv = CryptoJS.enc.Hex.parse(ivStr);
		const encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), key, { iv });
		return this.readingHistoryRepository
			.logChapterViewForVisitor({ p: encrypted.toString() })
			.pipe(
				map((response: any) => {
					return !!response;
				})
			);
	}

	logPostShareCount(postId: string): void {
		this.readingHistoryRepository
			.logShare(postId)
			.pipe(
				map((response: any) => {
					return !!response;
				})
			)
			.subscribe();
	}

	getReadingHistory(username: string): Observable<IResponseSimplifiedPost[]> {
		return this.readingHistoryRepository.getReadingHistory(username).pipe(
			map((result: any) => {
				if (result) {
					return result.data;
				} else {
					return [];
				}
			})
		);
	}
}
