import { Component } from '@angular/core';
import RoutingConstant from '../../../app-routing.constant';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
	selector: 'app-dialog-confirm-publisher',
	templateUrl: './dialog-confirm-publisher.component.html',
	styleUrls: ['./dialog-confirm-publisher.component.scss']
})
export class DialogConfirmPublisherComponent {
	RoutingConstant = RoutingConstant;

	constructor(public dialogRef: MatDialogRef<DialogConfirmPublisherComponent>) {}

	onCancel() {
		this.dialogRef.close(false);
	}
}
