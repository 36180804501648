import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBasePost, IRequestPublishPost } from '../interface/post.interface';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class PostImplRepository {
	constructor(private http: MsUserHttpClient) {}

	publishPost(params: IBasePost): Observable<any> {
		return this.http.post('/post', params);
	}

	createPostAsDraft(params: Partial<IBasePost>): Observable<any> {
		return this.http.post('/post/draft', params);
	}

	getPostAllPostByCategoryAndGenre(query: string): Observable<any> {
		return this.http.get(`/posts${query}`);
	}

	updatePostAsDraft(params: Partial<IBasePost>, id: string): Observable<any> {
		return this.http.patch(`/post/draft/${id}`, params);
	}

	updatePost(params: IRequestPublishPost, id: string): Observable<any> {
		return this.http.patch(`/post/${id}`, params);
	}

	savePendingChanges(
		postId: string,
		params: Omit<IBasePost, 'id' | 'isMonetized'>
	): Observable<any> {
		return this.http.patch(`/post/pending/${postId}`, params);
	}

	getDetailPost(id: string): Observable<any> {
		return this.http.get(`/post/${id}`);
	}

	getDetailPostForWriter(id: string): Observable<any> {
		return this.http.get(`/writer/post/${id}`);
	}

	getRandomPost(query: object): Observable<any> {
		return this.http.get('/post/random', {
			params: {
				...query
			}
		});
	}

	deletePost(id: string): Observable<any> {
		return this.http.delete(`/post/${id}`);
	}

	getPopularMonetizedPost(query: string): Observable<any> {
		return this.http.get(`/post/popular/monetized${query}`);
	}

	getPopularPost(query: string): Observable<any> {
		return this.http.get(`/post/popular${query}`);
	}

	getLatestPost(query: string): Observable<any> {
		return this.http.get(`/post/latest${query}`);
	}

	getDefaultCoverImage(): Observable<any> {
		return this.http.get('/cover/default');
	}

	getEligiblePostForWritingEvent(): Observable<any> {
		return this.http.get(`/writer/posts/eligible-for-event`);
	}
}
