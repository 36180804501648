import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentUsecase } from '@domain/usecase/payment.usecase';

@Component({
	selector: 'app-dialog-paid-confirmation',
	templateUrl: './dialog-paid-confirmation.component.html',
	styleUrls: ['./dialog-paid-confirmation.component.scss']
})
export class DialogPaidConfirmationComponent {
	availableCoin = 0;
	state: 'success' | 'failed' | 'idle' | 'loading' = 'idle';

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			id: string;
			price: number;
			message: string;
			invalidMessage: string;
			successMessage: string;
			successCallback?: () => void;
		},
		public dialogRef: MatDialogRef<DialogPaidConfirmationComponent>,
		private router: Router,
		private paymentUsecase: PaymentUsecase
	) {}

	onCloseDialog() {
		this.dialogRef.close(false);
	}

	onSuccess() {
		this.dialogRef.close(true);
	}

	goToTopUpPage() {
		this.dialogRef.close(false);
		this.router.navigate([`/topup`]);
	}

	callSuccessCallback() {
		this.data.successCallback?.();
	}

	buyPackage() {
		this.state = 'loading';
		this.paymentUsecase.purchaseWacaAiPackage(this.data.id).subscribe({
			next: (result) => {
				this.state = 'success';
			},
			error: (err) => {
				this.state = 'failed';
			}
		});
	}
}
