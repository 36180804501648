<div mat-dialog-content class="flex flex-col items-center justify-center md:w-[420px] rounded-lg">
	<div class="header-dialog"></div>
	<div class="pt-5 text-center">
		<div class="font-semibold text-xl">Cobain InkCraft Terbaru!!!</div>
		<div class="px-10 text-center mt-4">
			Sekarang InkCraft sudah menggunakan tampilan mobile lho. Kamu bisa lebih leluasa
			mengatur karya kamu dengan lebih mudah lewat handphone. Cobain sekarang yuk!
		</div>
		<div class="mt-5 divide-y border-t border-color-[#e5e5e5]">
			<div
				class="py-4 text-red-500 font-semibold cursor-pointer"
				(click)="onDirectInkCraft()">
				Mulai Menulis
			</div>

			<div class="py-4 cursor-pointer" (click)="onCancel()">Batalkan</div>
		</div>
	</div>
</div>
