<div class="bottom-navigation" *ngIf="!isMobileAppView">
	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		[routerLink]="[RoutingConstant.SHOP_HOME]">
		<div
			*ngIf="currentRoute === RoutingConstant.SHOP_HOME; else notActive"
			style="background-color: transparent">
			<img src="../../../../assets/shop/icon-shop-active.svg" class="m-auto" />
			<span class="text-xs font-semibold active-text">Buku Dijual</span>
		</div>
		<ng-template #notActive>
			<img src="../../../../assets/shop/icon-shop-grey.svg" class="m-auto" />
			<span class="text-xs font-medium text-gray-500">Buku Dijual</span>
		</ng-template>
	</div>

	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		[routerLink]="[RoutingConstant.SHOP_MY_COLLECTION]">
		<div
			*ngIf="currentRoute === RoutingConstant.SHOP_MY_COLLECTION; else notActiveCollection"
			style="background-color: transparent">
			<img src="../../../../assets/shop/icon-mycollection-active.svg" class="m-auto" />
			<span class="text-xs font-semibold active-text">Koleksi Saya</span>
		</div>
		<ng-template #notActiveCollection>
			<img src="../../../../assets/shop/icon-mycollection-grey.svg" class="m-auto" />
			<span class="text-xs font-medium text-gray-500">Koleksi Saya</span>
		</ng-template>
	</div>
	<div
		class="flex-col flex-1 text-center self-end cursor-pointer"
		[routerLink]="[RoutingConstant.SHOP_TRANSACTION]">
		<div
			*ngIf="currentRoute === RoutingConstant.SHOP_TRANSACTION; else notActiveTransaction"
			style="background-color: transparent">
			<img src="../../../../assets/shop/icon-shop-transaction-active.svg" class="m-auto" />
			<span class="text-xs font-semibold active-text">Transaksi</span>
		</div>
		<ng-template #notActiveTransaction>
			<img src="../../../../assets/shop/icon-shop-transaction-grey.svg" class="m-auto" />
			<span class="text-xs font-medium text-gray-500">Transaksi</span>
		</ng-template>
	</div>
</div>
