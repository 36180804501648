import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Clipboard } from '@angular/cdk/clipboard';
import { ReadingHistoryUsecase } from '@domain/usecase/reading-history.usecase';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-bottom-sheet-share',
	templateUrl: './bottom-sheet-share.component.html',
	styleUrls: ['./bottom-sheet-share.component.scss']
})
export class BottomSheetShareComponent implements OnInit {
	currentUrl = '';

	constructor(
		private clipboard: Clipboard,
		private route: ActivatedRoute,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
		private _bottomSheetRef: MatBottomSheetRef<BottomSheetShareComponent>,
		private readingHistoryUsecase: ReadingHistoryUsecase
	) {}

	ngOnInit(): void {
		this.currentUrl = window.location.href;
	}

	closeBottomSheet() {
		this._bottomSheetRef.dismiss(false);
	}

	sendToWhatsapp() {
		window.open(`whatsapp://send?text=${this.currentUrl}`);
		this.readingHistoryUsecase.logPostShareCount(this.data.postId);
		this._bottomSheetRef.dismiss(true);
	}

	copyLink() {
		this.clipboard.copy(this.currentUrl);
		this.readingHistoryUsecase.logPostShareCount(this.data.postId);
		this._bottomSheetRef.dismiss(true);
	}
}
