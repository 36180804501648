<div class="sticky top-0 bg-white z-10">
	<div class="flex pt-5 pb-3 px-5">
		<div class="flex-1 font-medium text-base md:text-lg">Bagikan Ebook</div>
		<div class="cursor-pointer" (click)="closeBottomSheet()">
			<mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
		</div>
	</div>
	<hr class="mt-1" />
</div>
<div class="flex flex-col px-4 pt-5">
	<!--Share whatsapp-->
	<div class="share-item" (click)="sendToWhatsapp()">
		<div class="share-icon whatsapp-bg">
			<img
				src="../../../../assets/icons/icon-whatsapp-white.svg"
				class="self-center m-2"
				alt="whatsapp" />
		</div>
		<div class="grow ml-4">
			<span>Whatsapp</span>
		</div>
	</div>

	<!--Share link-->
	<div class="share-item" (click)="copyLink()">
		<div class="share-icon bg-black">
			<mat-icon class="self-center m-2" [svgIcon]="'heroicons_outline:link'"> </mat-icon>
		</div>
		<div class="grow ml-4">
			<span>Salin link</span>
		</div>
	</div>
</div>
