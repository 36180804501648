export const environment = {
	production: false,
	msUserBaseUrl: 'https://dev-api-user.ignitr.co.id',
	msFileBaseUrl: 'https://dev-api-file.ignitr.co.id',
	msPaymentBaseUrl: 'https://dev-api-payment.ignitr.co.id',
	msAnalyticsBaseUrl: 'https://ms-analytics-zobpp72udq-as.a.run.app',
	msNotificationBaseUrl: 'https://dev-api-notification.ignitr.co.id',
	msStoreBaseUrl: 'https://ms-store-zobpp72udq-as.a.run.app',
	lsUserKey: 'cvG=@L,r@1$$bfh&vjf9$Q7V',
	lsAccessTokenKey: 'hzTEZH9y#mw=!Yiubn8l67LdB',
	lsRefreshTokenKey: '5e%XoO6hgKrTQs,cA$AE1i0D+',
	mixpanelToken: '9c6b2a68ea3dd86b9266a46e3c3c4386',
	SENTRY_AUTH_TOKEN: 'b305107a040440d3855986c627633997f13e360c69ca45f0900eff1dd61d3c45',
	INKCRAFT_URL: 'https://inkcraft.ignitr.co.id/',
	firebase: {
		projectId: 'waca-dev',
		appId: '1:307828156642:web:81b8e6d9d3373e30f356bb',
		storageBucket: 'waca-dev.appspot.com',
		apiKey: 'AIzaSyDECvg6QdZPQQGsd5XBw_GJkZ7pUFtpfVU',
		authDomain: 'waca-dev.firebaseapp.com',
		messagingSenderId: '307828156642',
		measurementId: 'G-CMMLWHW4LW'
	}
};
