import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmPublisherComponent } from './dialog-confirm-publisher.component';

@Injectable({
	providedIn: 'root'
})
export class DialogConfirmationPublisherService {
	dialogRef: MatDialogRef<DialogConfirmPublisherComponent> | null = null;

	constructor(private dialog: MatDialog) {}

	public open() {
		this.dialogRef = this.dialog.open(DialogConfirmPublisherComponent);
	}
}
