import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import RoutingConstant from '../../../app-routing.constant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-dialog-confirm-writer',
	templateUrl: './dialog-confirm-writer.component.html',
	styleUrls: ['./dialog-confirm-writer.component.scss']
})
export class DialogConfirmWriterComponent {
	RoutingConstant = RoutingConstant;
	inkcraftUrl = environment.INKCRAFT_URL;
	constructor(
		public dialogRef: MatDialogRef<DialogConfirmWriterComponent>,
		private router: Router,
		private authenticationUsecase: AuthenticationUsecase
	) {}

	onDirectInkCraft() {
		const token = this.authenticationUsecase.getToken();
		window.open(`${this.inkcraftUrl}home?token=${token}`, '_blank');
		this.dialogRef.close(false);
	}

	onExistingWriter() {
		this.router.navigate([RoutingConstant.LIST_KARYA]);
		this.dialogRef.close(false);
	}

	onCancel() {
		this.dialogRef.close(false);
	}
}
