import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentUsecase } from '@domain/usecase/payment.usecase';

@Component({
	selector: 'app-user-waca-koin',
	templateUrl: './user-waca-koin.component.html',
	styleUrls: ['./user-waca-koin.component.scss']
})
export class UserWacaKoinComponent implements OnInit {
	@Input()
	isShowLabel = true;

	@Output()
	public availableCoin = new EventEmitter<number>();

	balance = 0;
	isLoading = true;
	errorMessage = '';

	constructor(private paymentUsecase: PaymentUsecase) {}

	ngOnInit(): void {
		this.isLoading = true;
		this.paymentUsecase.getBalance().subscribe({
			next: (response: any) => {
				this.isLoading = false;
				this.balance = response.balance;
				this.availableCoin.emit(this.balance);
			},
			error: (err) => {
				this.isLoading = false;
				this.errorMessage = err;
			}
		});
	}
}
