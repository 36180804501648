const symbolRegex = /[^a-zA-Z0-9\s]/gi;

const escapeHtml = (text: string) =>
	text
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#039;');

const generatePostUrl = (postId: string, postTitle: string) => {
	const formattedTitlePost = postTitle.replace(symbolRegex, '').replace(/ /g, '-');
	return `/post/${formattedTitlePost}-${postId}`.toLowerCase();
};

const generateEbookUrl = (ebookId: string, ebookTitle: string) => {
	const formattedTitlePost = ebookTitle.replace(symbolRegex, '').replace(/ /g, '-');
	return `/pustaka/book/${formattedTitlePost}-${ebookId}`.toLowerCase();
};

const generateChapterUrl = (
	postId: string,
	postTitle: string,
	chapterId: string,
	chapterTitle: string
) => {
	const formattedTitlePost = postTitle.replace(symbolRegex, '').replace(/ /g, '-');
	const formattedTitleChapter = chapterTitle.replace(symbolRegex, '').replace(/ /g, '-');
	return `/post/${formattedTitlePost}-${postId}/${formattedTitleChapter}-${chapterId}`.toLowerCase();
};

const urlHelper = {
	escapeHtml,
	generatePostUrl,
	generateChapterUrl,
	generateEbookUrl
};
export default urlHelper;
