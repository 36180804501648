import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class AcquisitionImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getAcquisitionBySlug(url: string): Observable<any> {
		return this.http.get(`/acquisition/${url}`);
	}

	registerAcquisition(params: object, id: string): Observable<any> {
		return this.http.post(`/acquisition/${id}/register`, params);
	}

	checkAcquisitionRegistrationStatus(id: string): Observable<any> {
		return this.http.get(`/acquisition/${id}/status`);
	}

	getSubmittedPost(id: string): Observable<any> {
		return this.http.get(`/acquisition/${id}/submitted`);
	}

	submitPost(id: string, postIds: string[]): Observable<any> {
		return this.http.post(`/acquisition/${id}/submit`, {
			postIds
		});
	}

	getEligiblePost(acquisitionId: string): Observable<any> {
		return this.http.get(`/acquisition/posts/eligible-for-acquisition`, {
			params: {
				acquisitionId
			}
		});
	}
}
