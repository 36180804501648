import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, retry, switchMap, take, timeout } from 'rxjs/operators';
import { HTTP_CONFIG } from '../constants';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { LoginDialogComponent } from '@components/login-dialog/login-dialog.component';
import { DialogNotificationComponent } from '@components/dialog-notification/dialog-notification.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import CryptoJS from 'crypto-js';
import { IErrorDetail } from '@data/interface/error.interface';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { LocalstorageDatasource } from '@data/datasources/localstorage/localstorage.datasource';
import * as Sentry from '@sentry/angular-ivy';

const moment = _rollupMoment || _moment;

@Injectable()
export class WacaHttpInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private dialogRef: any;
	private notificationDialogRef: any;

	constructor(
		private authenticationUsecase: AuthenticationUsecase,
		private dialog: MatDialog,
		private router: Router,
		private userService: UserService
	) {}

	intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const userToken = this.authenticationUsecase.getToken();

		let interceptedRequest = httpRequest.clone({
			// url: `${baseUrl}${httpRequest.url}`,
			headers: httpRequest.headers.set('Authorization', `Bearer ${userToken}`)
		});

		if (httpRequest.headers.has('ms') && httpRequest.headers.get('ms') === 'payment') {
			interceptedRequest = this.generateRequestAdditionHeaderForPayment(
				httpRequest,
				userToken || ''
			);
		}

		let timeoutValue = HTTP_CONFIG.API_TIMEOUT; // Default timeout value
		const timeoutHeader = httpRequest.headers.get('timeout');

		if (timeoutHeader) {
			timeoutValue = Number(timeoutHeader);
			interceptedRequest = interceptedRequest.clone({
				headers: interceptedRequest.headers.delete('timeout')
			});
		}

		return next.handle(interceptedRequest).pipe(
			timeout(timeoutValue),
			retry(HTTP_CONFIG.DEFAULT_RETRY),
			catchError((error: HttpErrorResponse) => {
				let errorMsg = '';
				if (error.error instanceof ErrorEvent) {
					// This is client side error
					errorMsg = `Error: ${error.error.message}`;
					Sentry.captureException(error.error);
				} else {
					// This is server side error
					// mapping error
					if (error.status === HttpStatusCode.Unauthorized) {
						// re-login user if refresh token expired
						if (this.authenticationUsecase.isRefreshTokenExpired()) {
							this.displayLoginDialog();
						} else {
							// refresh token is not expired, just get new access token
							return this.handle401Error(httpRequest, next);
						}
					} else {
						Sentry.captureException(error.error);
						const errorResponse = error.error.error;
						errorMsg = `${errorResponse.message}`;
						if (errorResponse.errors) {
							errorResponse.errors.forEach((err: IErrorDetail) => {
								errorMsg += ` ${err.message}`;
							});
						}
						// this.displayNotificationDialog(apiErrorMsg);
					}
				}
				return throwError(() => errorMsg);
			})
		);
	}

	private displayLoginDialog() {
		if (this.dialogRef) {
			return;
		}

		this.dialogRef = this.dialog.open(LoginDialogComponent, {
			width: '400px',
			panelClass: 'custom-dialog-container',
			data: {
				redirectToHomepageOnCancel: true
			}
		});

		this.dialogRef.afterClosed().subscribe({
			next: (res: any) => {
				if (res) {
					window.location.reload();
				}
				this.dialogRef = undefined;
			}
		});
	}

	private displayNotificationDialog(message: string) {
		if (this.notificationDialogRef) {
			return;
		}

		this.notificationDialogRef = this.dialog.open(DialogNotificationComponent, {
			width: '400px',
			data: {
				message
			}
		});

		this.notificationDialogRef.afterClosed().subscribe({
			next: () => {
				this.notificationDialogRef = undefined;
			}
		});
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			const token = this.authenticationUsecase.getRefreshToken();

			if (token) {
				const payload = {
					refreshToken: token
				};

				return this.authenticationUsecase.refreshAccessToken(payload).pipe(
					switchMap((result: any) => {
						this.isRefreshing = false;
						this.authenticationUsecase.setToken(result.data.token);
						this.refreshTokenSubject.next(result.data.token);

						return next.handle(this.addTokenHeader(request, result.data.token));
					}),
					catchError((err) => {
						this.isRefreshing = false;
						this.authenticationUsecase.logout();
						this.userService.clearEvent();
						const storage = new LocalstorageDatasource();
						storage.clear();
						this.displayLoginDialog();
						return throwError(err);
					})
				);
			}
		}

		return this.refreshTokenSubject.pipe(
			filter((token) => token !== null),
			take(1),
			switchMap((token) => next.handle(this.addTokenHeader(request, token)))
		);
	}

	private addTokenHeader(request: HttpRequest<any>, token: string) {
		return request.clone({
			// url: `${baseUrl}${request.url}`,
			headers: request.headers.set('Authorization', `Bearer ${token}`)
		});
	}

	private generateRequestAdditionHeaderForPayment(request: HttpRequest<any>, userToken: string) {
		const timeStamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		const requestId = uuidv4();
		const encoder = CryptoJS.enc.Hex;
		// TODO: get config from service instead of hardcode
		const clientId = `e1_3nmQ'^wS8S7fYwGKf%gWwkzT0{`;
		const clientSecret = `m76bx3fBeLs.tdI+{qesIk[b_ssQLH`;
		const rawSaltKey = `iYxp5YvoYd8zvy5j20km20zR3bCCHQ`;
		const saltKey = CryptoJS.SHA256(rawSaltKey + ':' + requestId).toString(encoder);
		const password = CryptoJS.SHA256(clientSecret + ':' + timeStamp).toString(encoder);
		const base64 = btoa(clientId + ':' + password + ':' + timeStamp + ':' + saltKey);
		const sha256 = CryptoJS.SHA256(base64).toString(encoder);
		const apiKey = CryptoJS.SHA256(sha256).toString(encoder);

		return request.clone({
			headers: request.headers
				.delete('ms')
				.set('Authorization', `Bearer ${userToken}`)
				.set('Content-Type', 'application/json')
				.set('X-TIMESTAMP', timeStamp)
				.set('X-REQUEST-ID', requestId)
				.set('X-CLIENT-ID', clientId)
				.set('X-API-KEY', apiKey)
		});
	}
}
