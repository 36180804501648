import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Scroll } from '@angular/router';
import RoutingConstant from 'src/app/app-routing.constant';
@Component({
	selector: 'app-bottom-navigation-shop',
	templateUrl: './bottom-navigation-shop.component.html',
	styleUrls: ['./bottom-navigation-shop.component.scss']
})
export class BottomNavigationShopComponent {
	RoutingConstant = RoutingConstant;
	isMobileAppView = false;
	currentRoute = '/shop';

	constructor(private router: Router, private activeRoute: ActivatedRoute) {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd || Scroll)) {
				return;
			}

			if (evt instanceof NavigationEnd) {
				this.currentRoute = evt.url;
			}
			window.scrollTo(0, 0);

			// Get query parameters from the active route snapshot
			const snapshot = this.activeRoute.snapshot;
			const queryParams = snapshot.queryParams;
			const isMobileAppView = queryParams['isMobileAppView'];

			if (isMobileAppView) {
				this.isMobileAppView = isMobileAppView.toLowerCase() === 'true';
			}
		});
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.currentRoute = event.url;
			}
		});
	}
}
