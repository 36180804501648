<div
	*ngIf="!isLoading"
	class="flex flex-row max-w-fit justify-center items-center bg-white border-2 border-gray-100 px-4 py-3 rounded-xl cursor-pointer">
	<span class="mr-2 text-base font-medium" *ngIf="isShowLabel">Koin kamu</span>
	<img src="/assets/gif/wacacoin.gif" class="w-7 mr-2.5" alt="waca-coin" />
	<div class="text-base font-semibold mr-2" [countUp]="balance" [duration]="1000"></div>
</div>

<div
	*ngIf="isLoading"
	class="flex flex-row w-32 h-14 justify-center items-center bg-slate-100 px-4 py-3 rounded-xl cursor-pointer">
	<span class="balance-loader"></span>
</div>
