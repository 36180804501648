<div mat-dialog-content class="flex flex-col items-center justify-center max-w-[480px]">
	<!--idle state-->
	<ng-container *ngIf="state === 'idle'">
		<app-user-waca-koin [isShowLabel]="true" (availableCoin)="availableCoin = $event">
		</app-user-waca-koin>

		<!--content-->
		<div
			class="w-full text-center mt-3 mb-5"
			[innerHTML]="availableCoin >= data.price ? data.message : data.invalidMessage"></div>

		<!--Action-->
		<div class="w-full max-w-500 flex flex-row gap-3 items-center justify-center">
			<app-button
				(click)="onCloseDialog()"
				class="w-2/6"
				[text]="'Batal'"
				[isOutline]="true"
				[customClass]="'w-full'"></app-button>

			<app-button
				*ngIf="availableCoin === 0 || availableCoin < data.price"
				(click)="goToTopUpPage()"
				class="w-4/6"
				[text]="'Top Up'"
				[isOutline]="false"
				[customClass]="'w-full'"></app-button>

			<button
				class="btn-confirmation waca-bg-primary w-4/6"
				*ngIf="availableCoin >= data.price"
				(click)="buyPackage()">
				<div class="flex flex-row w-full justify-center items-center">
					<div class="flex flex-1">Bayar</div>
					<div class="flex flex-row">
						<img src="/assets/gif/wacacoin.gif" class="w-6 mr-2.5" alt="waca-coin" />
						<div
							class="text-base font-semibold mr-2"
							[countUp]="data.price"
							[duration]="100"></div>
					</div>
				</div>
			</button>
		</div>
	</ng-container>

	<!--loading state-->
	<ng-container *ngIf="state === 'loading'">
		<div class="max-w-500 flex py-10 px-20 justify-center items-center">
			<mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
		</div>
	</ng-container>

	<!--success state-->
	<ng-container *ngIf="state === 'success'">
		<div class="max-w-500 flex flex-col justify-center items-center">
			<div class="text-center mb-5" [innerHTML]="data.successMessage"></div>
			<div class="w-full flex mobile:flex-col-reverse justify-center items-center sm:gap-4">
				<!--<app-button-->
				<!--	class="mb-3"-->
				<!--	(click)="onCloseDialog()"-->
				<!--	[text]="'Tutup'"-->
				<!--	[isOutline]="true"></app-button>-->
				<app-button
					class="mb-3"
					(click)="callSuccessCallback()"
					[customClass]="'flex-1'"
					text="Lanjutkan Menulis">
				</app-button>
			</div>
		</div>
	</ng-container>
</div>
